import React from "react"
import { useTranslation } from "react-i18next"
import HeroImg from "../../components/HeroImg/HeroImg"
import MainLayout from "../../layouts/MainLayout"
import "./production.scss"
import Slider from "../../components/Slider"

const Production = () => {
  const { t } = useTranslation()
  const slides = [
    {
      title: t("production:sliderOneTitle"),
      img: "/img/production-carousel-one.jpg",
      renderer: <p>{t("production:sliderOneText")}</p>,
    },
    {
      title: t("production:sliderTwoTitle"),
      img: "/img/production-carousel-two.jpg",
      renderer: <p>{t("production:sliderTwoText")}</p>,
    },
    {
      title: t("production:sliderThreeTitle"),
      img: "/img/production-carousel-three.jpg",
      renderer: <p>{t("production:sliderThreeText")}</p>,
    },
    {
      title: t("production:sliderFourTitle"),
      img: "/img/production-carousel-four.jpg",
      renderer: <p>{t("production:sliderFourText")}</p>,
    },
    {
      title: t("production:sliderFiveTitle"),
      img: "/img/production-carousel-five.jpg",
      renderer: <p>{t("production:sliderFiveText")}</p>,
    },
  ]
  return (
    <MainLayout metaTitle={t("production:metaTitle")}>
      <>
        <HeroImg
          customClass="production-hero"
          customSubtitle="production-subtitle"
          subtitle={t("production:heroSubtitle")}
          title={t("production:heroTitle")}
          backgroundImage="bg/production-hero.jpg"
        ></HeroImg>

        <div className="production">
          <div className="production-section-one">
            <div className="production-section-one-text-container">
              <h3>{t("production:textOne")}</h3>
              <h3 className="box-two">
                {t("production:textTwo")}{" "}
              </h3>
              <p className="box-two">
                {t("production:textThree")}
              </p>
              <p className="box-two">
                {t("production:textFour")}
              </p>
              <p className="box-two">
                {t("production:textFive")}
              </p>
            </div>
            <img src="/img/production-img-one.jpg" alt="production-one" />
          </div>

          <div className="production-section-two">
            <div className="position-number">
              <h3 className="box-two">
                {t("production:25TextOne")}
              </h3>
              <p>
                {t("production:25TextTwo")}
              </p>
              <p>{t("production:25TextThree")}</p>
              <div className="number">25</div>
            </div>
          </div>

          <div className="production-section-three">
            <h2>{t("production:sliderTitle")}</h2>

            <div className="card-for-desktop">
              <Slider slides={slides} />
            </div>

            <div className="card-for-mobile">
  
              <div className="card">
                <img
                  src={slides[0].img}
                  alt="production-one"
                />
                <div className="card-text">
                  <h5>01</h5>
                  <h3 className="box-two">{slides[0].title}</h3>
                  {slides[0].renderer}
                </div>
              </div>

              <div className="card">
                <img
                  src={slides[1].img}
                  alt="production-two"
                />
                <div className="card-text">
                  <h5>02</h5>
                  <h3 className="box-two">{slides[1].title}</h3>
                  {slides[1].renderer}
                </div>
              </div>

              <div className="card">
                <img
                  src={slides[2].img}
                  alt="production-three"
                />
                <div className="card-text">
                  <h5>03</h5>
                  <h3 className="box-two">{slides[2].title}</h3>
                  {slides[2].renderer}
                </div>
              </div>

              <div className="card">
                <img
                  src={slides[3].img}
                  alt="production-four"
                />
                <div className="card-text">
                  <h5>04</h5>
                  <h3 className="box-two">{slides[3].title}</h3>
                  {slides[3].renderer}
                </div>
              </div>

              <div className="card">
                <img
                  src={slides[4].img}
                  alt="production-five"
                />
                <div className="card-text">
                  <h5>05</h5>
                  <h3 className="box-two">{slides[4].title}</h3>
                  {slides[4].renderer}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </MainLayout>
  )
}

export default Production
