import React, { useState } from "react"
import 'simplebar';
import 'simplebar/dist/simplebar.css';
import "./slider.scss"

const formatNumber = index => {
  const number = index + 1
  return number.toString().padStart(2, 0)
}

const Slider = ({ slides }) => {
  const [active, setActive] = useState(0)
  const currentSlide = slides[active]
  // console.clear()

  const next = () => {
    if (active + 1 === slides.length) return
    setActive(active + 1)
  }

  const prev = () => {
    if (active === 0) return
    setActive(active - 1)
  }

  const calculateWidth = () => {
    return `${Math.ceil(100 / slides.length)}%`
  }

  return (
    <div className="slider">
      <div className={`slider-image`}>
        {slides.map((slide, index) => (
          <img
            src={slide.img}
            className={active === index && "slider-active"}
            alt="slide"
            key={slide.img}
          />
        ))}
      </div>

      <div className="slider-content-container">
        <div className="slider-content">
          <div className="slider-content-order">{formatNumber(active)}</div>
          <h3 className="box-two">{currentSlide.title}</h3>
          <div className="slider-content-text" data-simplebar data-simplebar-auto-hide="false">{currentSlide.renderer}</div>
        </div>
        <div className="slider-bar">
          <img src="/img/arrow-left.svg" alt="back" onClick={prev} />
          <h5 className="slider-bar-number">
            {active + 1} od {slides.length}
          </h5>
          <img src="/img/arrow-right.svg" alt="next" onClick={next} />
          <div className="slider-bar-line">
            {slides.map((s, index) => {
              return (
                <div
                  key={s.title}
                  className="slider-bar-line-active"
                  style={{
                    width: calculateWidth(),
                    opacity: index === active ? 1 : 0,
                  }}
                />
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Slider
